.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html,BODY{
 background: url(./images/bg.png);
 height:100%;
 margin: 0 auto;
}
#root{
 height:100%;
 margin: 0 auto;
}

a.nav-link{
	color: red !important;
}
.navbar{
	border-bottom: 1px solid #d0d0d0;
	height: 87px;
}
.navbar-brand{
	padding: 0;
	margin: 0;
	height: 70px;
	overflow: hidden;
	position:absolute;
	left:0;
	right: 180px;
	max-width: 560px;
}
.navbar-brand img{
	position: relative;
	margin-top: 0px;
	padding-right: 10px;
	width: 33%;
}

.loginform{
	max-width: 600px;
	min-width: 320px;
	margin: auto;
	background: rgba(248,249,250,0.8);
	margin: 10px auto -75px;
	border: 1px solid #d0d0d0 !important;
	border-radius: 15px;
}
.main{
	max-width: 960px;
	min-width: 320px;
	margin: 10px auto -75px;
	background: rgba(248,249,250,0.8);
	min-height: 70%;
	border: 1px solid #d0d0d0;
	border-radius: 15px;
	padding: 20px;
}

#phoneCode{
	width: 70%;
	display: inline;
}
#getPhoneCode{
	width: 28%;
	float: right;
}


.emptyField{
	border: 1px solid green;
}

.modal960{
	max-width: 960px;
}
.sample_card{
	width: 100%;
	margin-bottom: 25px;
	border: 1px solid black !important;
	border-radius: 15px;
}
.transactions, .cheque{
	width: 100%;
}
.transactions th{
	text-align: center;
	background-color: lightgrey;
	border: 1px solid white;
}
.transactions td{
	text-align: center;
}

input[type="date"]
{
    display:block;

    /* Solution 1 */
     -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;

    /* Solution 2 */
    /* min-width: 96%; */
}


.wallet_button {
	width: 50%;
}
